import React from 'react';

import { graphql } from 'gatsby';

import EmailLinkSentPage from '../components/email-link-sent/variants/organic-traffic';

export const query = graphql`
  query EmailLinkSentOrganicTrafficPageQuery(
    $pageId: String!
    $breakpoints: [Int!]
  ) {
    kalansoPage(pageId: { eq: $pageId }) {
      ...KalansoFullPageFragment
    }
  }
`;

const EmailLinkSentTemplate = (props) => {
  return <EmailLinkSentPage {...props} />;
};

export default EmailLinkSentTemplate;
