import React, { Suspense } from 'react';

import Header from '../../shared/header-without-call-to-action';
import Footer from '../../shared/footer';
import BackgroundCloudinaryArtDirected from '../../shared/background-cloudinary-art-directed';

import proxies from '../../letters/proxies';

import {
  useHeadless,
  selectBackgroundImages,
  selectCustomModule,
} from '../../../context/headless-provider';

const PageContent = ({ data }) => {
  const backgroundImages = useHeadless(selectBackgroundImages);
  const customModule = useHeadless(selectCustomModule);
  const page = data.kalansoPage;

  const ThankYouLetter = proxies[customModule.custom.name];

  return (
    <>
      <Header page={page} />
      <BackgroundCloudinaryArtDirected mediaAssets={backgroundImages}>
        <Suspense fallback={null}>
          <ThankYouLetter page={page} />
        </Suspense>
      </BackgroundCloudinaryArtDirected>
      <Footer page={page} />
    </>
  );
};

export default PageContent;
