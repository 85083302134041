import React from 'react';

import { GatsbyImage, withArtDirection, getImage } from 'gatsby-plugin-image';
import { Box, Container } from '@mui/material';

const ordering = ['xl', 'lg', 'md', 'sm', 'xs', 'none'];

// See: https://mui.com/material-ui/customization/breakpoints/
const breakpoints = {
  none: 0,
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

export const getMediaQueryByWidth = (asset) => {
  if (breakpoints[asset.artDirection]) {
    return `(min-width: ${breakpoints[asset.artDirection]}px)`;
  }

  return `(min-width: ${breakpoints.none}px`;
};

const findAssetByBreakpoint = (ordering, assets) => {
  for (const breakpoint of ordering) {
    const found = assets.find((asset) => asset.artDirection === breakpoint);
    if (found) {
      return found;
    }
  }
};

const getImageVisibility = (smallest, breakpoint) => {
  // If no art direction, then image will be visible at all sizes
  if (smallest.artDirection === 'none') {
    return 'initial';
  }

  // If art direction, then image will be visible only on the supported breakpoints
  return breakpoints[breakpoint] < breakpoints[smallest.artDirection]
    ? 'none'
    : 'initial';
};

// See: https://www.gatsbyjs.com/docs/how-to/images-and-media/using-gatsby-plugin-image/#background-images
const BackgroundCloudinaryArtDirected = ({ children, mediaAssets = [] }) => {
  if (mediaAssets.length === 0) {
    return children;
  }

  // Find the asset with the largest breakpoint
  const largest = findAssetByBreakpoint(ordering, mediaAssets);

  // Find the asset with the smallest breakpoint
  const smallest = findAssetByBreakpoint(ordering.reverse(), mediaAssets);

  const images = withArtDirection(
    getImage(largest.cloudinary.gatsbyImageData),
    ordering
      .map((order) => {
        const asset = mediaAssets.find((asset) => asset.artDirection === order);
        if (asset) {
          const media = getMediaQueryByWidth(asset);
          const image = getImage(asset.cloudinary.gatsbyImageData);
          return {
            media,
            image,
          };
        }
      })
      .filter(Boolean),
  );

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          '& img': {
            display: {
              xs: getImageVisibility(smallest, 'xs'),
              sm: getImageVisibility(smallest, 'sm'),
              md: getImageVisibility(smallest, 'md'),
              lg: getImageVisibility(smallest, 'lg'),
              xl: getImageVisibility(smallest, 'xl'),
            },
          },
        }}
      >
        {/* You can use a GatsbyImage component if the image is dynamic */}
        <GatsbyImage
          className={[].filter(Boolean)}
          imgClassName={[].filter(Boolean)}
          loading="lazy"
          style={{
            gridArea: '1/1',
            minHeight: '100vh',
            maxHeight: '100vh',
          }}
          layout="fullWidth"
          // This is a presentational image, so the alt should be an empty string
          alt=""
          formats={['auto', 'webp', 'avif']}
          image={images}
        />
        <Box
          sx={{
            // By using the same grid area for both, they are stacked on top of each other
            gridArea: '1/1',
            position: 'relative',
            // This centers the other elements inside the hero component
            placeItems: 'center',
            display: 'grid',
          }}
        >
          {/* Any content here will be centered in the component */}
          <Container maxWidth="xl" sx={{ height: '100%', zIndex: 1 }}>
            {children}
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default BackgroundCloudinaryArtDirected;
